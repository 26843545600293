import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { environment } from 'src/environments/environment';
import { KpagosInformacionPremiumComponent } from '../kpagos-informacion-premium/kpagos-informacion-premium.component';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Component({
  selector: 'app-kpagos-pendiente',
  templateUrl: './kpagos-pendiente.component.html',
  styleUrls: ['./kpagos-pendiente.component.scss']
})
export class KpagosPendienteComponent implements OnInit {
  urlKpagos: string = environment.kPagosURL + 'suscripciones/mis-suscripciones';

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public formatter: FormatterService,
    private modalService: NgbModal,
    private sesion: SesionService
  ) { }

  ngOnInit() {
  }

  esCuentaPrueba() {
    return this.sesion.user.cuenta_prueba;
  }

  renovarPlan() {
    const modalRef = this.modalService.open(KpagosInformacionPremiumComponent, { size: 'xl', scrollable: true, centered: true });
    modalRef.result.then((result: any) => {
      this.activeModal.close();
    }, (reason: any) => {
      this.activeModal.close();
    });
  }

  openLink() {
    window.open(this.urlKpagos, '_blank');
  }

  cerrarSesion() {
    this.activeModal.close({ cerrarSesion: true });
  }

}
