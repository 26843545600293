import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownService } from 'ngx-markdown';
import { KpagosPagoEnProcesoComponent } from '../kpagos-pago-en-proceso/kpagos-pago-en-proceso.component';

@Component({
  selector: 'app-kpagos-terminos',
  templateUrl: './kpagos-terminos.component.html',
  styleUrls: ['./kpagos-terminos.component.scss']
})
export class KpagosTerminosComponent implements OnInit {

  @Input() tipo: number = 0;

  md: string = '';
  constructor(
    private http: HttpClient,
    private mdService: MarkdownService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.loadToS();
  }

  async loadToS() {
    this.http.get(`/assets/tos/tos.md`,
      { responseType: 'text' }).subscribe({
        next: (res: any) => {
          this.md = this.mdService.compile(res);
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }

  aceptar(){
    const modalRef = this.modalService.open(KpagosPagoEnProcesoComponent,{ size: 'lg', scrollable: true,  centered: true });
    modalRef.componentInstance.tipo = this.tipo;
    modalRef.result.then((result: any) => {
      this.activeModal.close(true);
    }, (reason: any) => {
      this.activeModal.close(true);
    });
  }

}
