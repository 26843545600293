import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/utils/config/config.service';
import { Observable } from 'rxjs';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
@Injectable({
  providedIn: 'root'
})
export class ProductoService {
  controller: string = "producto/";
  id!: number
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService
  ) { }

  create(data: any, file: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if(file) formData.append('file', file, file.name);
    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    })
    return this.http.post(this.config.apiUrl + this.controller + 'create', formData, this.sesion.headersFile);
  }

  createByExcel(data: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + 'create-from-excel', { producto: data }, this.sesion.headers);
  }

  view(id: any): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "view/" + id, this.sesion.headers);
  }

  update(data: any, file: File | null): Observable<any> {
    const formData: FormData = new FormData();
    if (file) formData.append('file', file, file.name);
    Object.keys(data).forEach((key: string) => {
      formData.append(key, data[key]);
    })
    return this.http.put(this.config.apiUrl + this.controller + 'update/' + data.id, formData, this.sesion.headersFile);
  }

  activate(data: any): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'activate/' + data.id, this.sesion.headers);
  }
  deactivate(data: any): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'deactivate/' + data.id, this.sesion.headers);
  }
  delete(data: any): Observable<any> {
    return this.http.delete(this.config.apiUrl + this.controller + 'delete/' + data, this.sesion.headers);
  }
  select(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'select', this.sesion.headers);
  }
  selectVenta(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'select-venta', this.sesion.headers);
  }
  selectProductosStock(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'selectProductosStocks', this.sesion.headers);
  }
  historial_stock(id: number): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'historial_stock/' + id, this.sesion.headers);
  }
  stock_sucursales(id: number): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'stock_sucursales/' + id, this.sesion.headers);
  }
  setID(datos: any) {
    this.id = datos;
  }
  stockCritico(data: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + 'stock-critico', data, this.sesion.headers);
  }

  downloadExcelSubidaMasiva(): Observable<any>{
    return this.http.get(this.config.apiUrl + this.controller + 'download-excel-subida-masiva', this.sesion.headersBlob);
  }

  downloadExcelStockMasiva(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + 'download-excel-stock-masiva', this.sesion.headersBlob);
  }
}
