<div class="modal-header">
    <h4 *ngIf="soloDemo" class="modal-title">Contacto de Ayuda</h4>
    <h4 *ngIf="!soloDemo" class="modal-title">Contacto de Ayuda</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <h1 id="modal-title" *ngIf="soloDemo" class="m-3">Escríbenos a WhatsApp para ayudarte en el uso del sistema.</h1>
    <h1 id="modal-title" *ngIf="!soloDemo" class="m-3">Elija su método preferido para ayudarte en el uso del sistema.</h1>
    <br>
    <div class="container">
        <button  *ngIf="!soloDemo" type="button" class="btn btn-primary btn-block w-100 my-2 py-2" (click)="email()"><i class="fa-solid fa-envelope me-2"></i> Correo Electrónico a soporte@kuvemar.com</button>
        
        <button *ngIf="soloDemo" type="button" class="btn btn-success btn-block w-100 my-2 py-2" (click)="whatsApp()"><i class="fa-brands fa-whatsapp me-2"></i> Contacto Vía WhatsApp +56 9 4475 8039</button>

        <button *ngIf="!soloDemo" type="button" class="btn btn-success btn-block w-100 my-2 py-2" (click)="whatsAppKuvemar()"><i class="fa-brands fa-whatsapp me-2"></i> Contacto Vía WhatsApp +56 9 7515 5189</button>

        <!-- button *ngIf="!soloDemo" type="button" class="btn btn-secondary btn-block w-100 my-2 py-2" (click)="tel()"><i class="fas fa-phone me-2"></i> Llamar a +56 9 7515 5189</button-->
    </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancelar()"> Cerrar</button>
</div>