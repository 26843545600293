<div [ngClass]="{ 'modal-header header-info': !isDanger,  'modal-header header-danger': isDanger }">
    <label [ngClass]="{ 'header-info-title': !isDanger,  'header-danger-title': isDanger }">¡Información!</label>
</div>
<div class="modal-body">
    <div class="instrucciones">
        <!--img src="/assets/images/error.svg" class="imagen"-->
        <!--div class="header-info">
            <label class="header-info-title">¡Información!</label>
        </div-->
        <div class="body-info-alert">
            <br>
            <div *ngIf="tipoAlerta == 1">
                <p class="body-info-title">Los folios de los siguientes CAF están a punto de agotarse:</p>
                <ul>
                    <li class="line"><label class="key">Boletas:</label><label class="value">4 folios restantes</label>
                    </li>
                    <li class="line"><label class="key">Facturas:</label><label class="value">10 folios
                            restantes</label></li>
                    <li class="line"><label class="key">Notas de Débito:</label><label class="value">2 folios
                            restantes</label></li>
                    <li class="line"><label class="key">Notas de Crédito:</label><label class="value">1 folios
                            restantes</label></li>
                </ul>
                <br>
                <p class="body-info-description">Asegúrese de contar con el proceso de renovación de folios una vez
                    estos se agoten.</p>
            </div>

            <div *ngIf="tipoAlerta == 2">
                <p class="body-info-title">Los folios de los siguientes CAF están agotados:</p>
                <ul>
                    <li><b>Boletas</b></li>
                    <li><b>Facturas</b></li>
                    <li><b>Notas de Débito</b></li>
                    <li><b>Notas de Crédito</b></li>
                </ul>
                <br>
                <p class="body-info-description">Por favor solicite la generación de los nuevos CAF desde el sitio web
                    de SII y una vez generados súbalos al sistema.</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary w-50"
        (click)="activeModal.dismiss('Cancel click')">Cerrar</button>
</div>