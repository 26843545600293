import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss']
})
export class InfoAlertComponent implements OnInit {

  @Input() detalleError: string = '';
  @Input() finalText: string = '';
  @Input() isDanger: boolean = false;
  @Input() tipoAlerta: number = 0; // 1: para Folios por Vencer SII - 2: para folios vencidos SII

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void { }
}
