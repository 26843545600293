import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { UsuarioProfileComponent } from './components/usuario/usuario-profile/usuario-profile.component';
import { SesionService } from './utils/sesion/sesion.service';
import data from './utils/sesion/routes.json'
import { ProductoService } from './components/producto/producto.service';
import { SucursalService } from './components/sucursal/sucursal.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { VersionUpdateComponent } from './utils/version-update/version-update.component';
import { MarkdownService } from 'ngx-markdown';
import { KpagosPendienteComponent } from './components/kpagos/kpagos-pendiente/kpagos-pendiente.component';
import { KpagosInformacionPremiumComponent } from './components/kpagos/kpagos-informacion-premium/kpagos-informacion-premium.component';
import { InfoAlertComponent } from './utils/info-alert/info-alert.component';
import { ModalDeleteComponent } from './utils/modal-delete/modal-delete.component';
import { environment } from 'src/environments/environment';
import { ModalContactoSoporteComponent } from './shared/contacto-soporte/contacto-soporte.component';
import { DateService } from './utils/date/date.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  fechaAlertaVencimientoSuscripcionPrueba: string = '';
  fechaAlertaVencimientoSuscripcion: string = '';
  diasHastaVencimiento: number | null = null;

  routes: any[] = [];
  configRutas: { [index: string]: any } = data;

  index: any = {
    "page": 1,
    "sorted_asc": true,
    "sorted": false,
    "sorted_by": null,
    "filtered": false,
    "filters": [],
    "pageSize": 20
  };
  stockCriticos: Array<any> = [];
  habilitarSuscripciones = environment.habilitarSuscripciones;

  alertas: Array<any> = [];

  constructor(
    public sesion: SesionService,
    private router: Router,
    private loading: LoadingService,
    private alerts: AlertService,
    private modalService: NgbModal,
    private productoService: ProductoService,
    private ngSelectConfig: NgSelectConfig,
    private sucursalService: SucursalService,
    private mdService: MarkdownService,
    private date: DateService
  ) {
    Chart.register(ChartDataLabels);

    this.ngSelectConfig.notFoundText = 'Ningún elemento encontrado.';
    // this.ngSelectConfig.appendTo = 'body';
  }

  asignarRutas() {
    try {
      this.sesion.routes = this.configRutas[this.sesion.user.rol_id].routes;
      if (!this.sesion.conceptoActivado) {
        const indexPadre = this.sesion.routes.findIndex((route) => route.text === 'Taller');
        if (indexPadre != -1) {
          const indexConcepto = this.sesion.routes[indexPadre].children.findIndex((route: any) => route.text === 'Conceptos');
          if (indexConcepto !== -1) this.sesion.routes[indexPadre].children.splice(indexConcepto, 1);
        }
      }
      if ((this.sesion.user.rol_id == 1 || this.sesion.user.rol_id == 0) && this.sesion.user.parametros && this.sesion.user.parametros.sii_activado) {
        let aux = this.sesion.routes.slice(0);
        aux.push({
          text: 'SII',
          icon: 'receipt',
          route: 'sii/index'
        });
        this.sesion.routes = aux;
      }
    } catch (error) {
      setTimeout(() => {
        this.asignarRutas();
      }, 100);
    }
  }

  ngOnInit() {
    this.fechaAlertaVencimientoSuscripcionPrueba = this.date.format(this.date.addTime('+3 days'), 'yyyy-mm-dd HH:ii:ss');
    this.fechaAlertaVencimientoSuscripcion = this.date.format(this.date.addTime('+7 days'), 'yyyy-mm-dd HH:ii:ss');

    // Elimina el concepto del menú lateral
    this.sesion.routes = [];
    this.isLogged();
  }

  obtenerDiasHastaVencimiento(): number {
    if (!this.fechaAlertaVencimientoSuscripcionPrueba) return 0;
    if (this.diasHastaVencimiento != null) return this.diasHastaVencimiento ?? 0;

    this.diasHastaVencimiento = this.date.dateDiffFixed(new Date(), new Date(this.sesion.user.vencimiento_suscripcion), 'days');
    return this.diasHastaVencimiento ?? 0;
  }

  loadDataAfterLogin() {
    this.asignarRutas();
    this.cargarVersionUpdate();
    this.getSucursales();
    this.cargarStockCritico();
    if (environment.habilitarSuscripciones && this.sesion.user.rol_id != 0) this.checkPaymentState();
  }

  checkPaymentState() {
    this.loading.show()
    this.sesion.getPaymentState().subscribe({
      next: (res: any) => {
        this.loading.hide();
        if (!res.suscripcion_activa) {
          this.loading.show()
          this.sesion.checkPaymentState().subscribe({
            next: (res: any) => {
              this.loading.hide();
              const modalRef = this.modalService.open(KpagosPendienteComponent, { centered: true });
              modalRef.result.then((result: any) => {
                if (result.cerrarSesion) {
                  this.logout();
                  return;
                }
                this.checkPaymentState();
              }, (reason: any) => {
                this.checkPaymentState();
              });
            }, error: (err: any) => {
              this.loading.hide();
              if (err.response) this.alerts.addAlert(err.response, 'danger');
            }
          });
        }
      }, error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  cargarVersionUpdate() {
    this.sesion.getLatestVersion().subscribe({
      next: (version: any) => {
        if (!version) return;
        if (localStorage.getItem('latest-version') != version) {
          localStorage.setItem('latest-version', version);
          this.sesion.getLatestVersionMd().subscribe({
            next: (resMD: any) => {
              let md = this.mdService.compile(resMD);
              const modalRef = this.modalService.open(VersionUpdateComponent, { size: 'md', scrollable: true, windowClass: 'modal-version-update' });
              modalRef.componentInstance.md = md;
              modalRef.componentInstance.version = version;
              modalRef.result.then((result: any) => {
                location.reload();
              }, (reason: any) => {
                location.reload();
              });
            }, error: (err: any) => { }
          });
        }
      }, error: (err: any) => {
      }
    });
  }

  changePass() {
    let modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
    modalRef.result.then((result) => { }, (reason) => { })
  }

  verPerfil() {
    const modalRef = this.modalService.open(UsuarioProfileComponent, { windowClass: 'clear-modal', size: 'lg', scrollable: true });
    modalRef.componentInstance.element = this.sesion.user;
    modalRef.result.then((result) => { }, (reason) => { });
  }

  logout() {
    this.loading.show();
    this.sesion.clearSesion();
    this.loading.hide();
    this.router.navigate(['login']);
  }

  searchFn(term: string, item: any) {
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
    }
    let terms = removeAccents(term.toLowerCase()).split(' ');
    let result = true;
    let p = removeAccents(([item.nombre].join(' ')).toLowerCase());
    terms.forEach(t => {
      result = result && p.includes(t);
    });
    return result;
  }

  isLogged(): void {
    if (window.location.pathname === '/terminos') return;
    if (window.location.pathname === '/soporte') return;
    if (window.location.pathname === '/recuperar-password') return;
    if (window.location.pathname.includes('/restablecer')) return;
    if (this.sesion.token) {
      this.loading.show();
      this.sesion.isLogged().subscribe({
        next: (res) => {
          this.loading.hide();
          this.loadDataAfterLogin();
        },
        error: (err) => {
          console.error(err);
          this.sesion.clearSesion();
          this.router.navigate(['login']);
          this.loading.hide();
          this.alerts.addAlert("Sesión expirada.", "danger");
        }
      })
    } else {
      this.router.navigate(['login']);
    }
  }

  get showSidebarAndHeader(): boolean {
    if (this.router.url === '/login') return false;
    if (this.router.url === '/terminos') return false;
    if (this.router.url === '/soporte') return false;
    if (this.router.url === '/recuperar-password') return false;
    if (this.router.url.includes('/restablecer')) return false;
    return true;
  }

  verStockCritico() {
    this.router.navigate(['producto/stock-critico']);
  }

  getSucursales(): void {
    if (!this.sesion.token) return;
    this.sucursalService.misSucursales().subscribe({
      next: (res) => {
        this.sesion.sucursales = res.sucursales;
        if (this.sesion.sucursales.length > 0) {
          let localSucursalID = this.sesion.loadLocalSucursal();
          if (localSucursalID) {
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == localSucursalID);
            if (localSucursalID != -1) {
              this.sesion.sucursal = this.sesion.sucursales[this.sesion.sucursalIndex];
            } else {
              this.sesion.sucursal = this.sesion.sucursales[0];
              this.sesion.saveSucursal(this.sesion.sucursal.id);
            }
            return;
          }
          if (this.sesion.sucursal?.id != this.sesion.sucursales[0].id) {
            localStorage.setItem('sucursal', JSON.stringify(this.sesion.sucursales[0]));
            this.sesion.sucursal = this.sesion.sucursales[0];
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
            this.sesion.saveSucursal(this.sesion.sucursal.id);
          } else {
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  changeScope(ev: any): void {
    if (!ev.target.value) return;
    let s = this.sesion.sucursales[ev.target.value];
    localStorage.setItem('sucursal', JSON.stringify(s));
    this.sesion.sucursal = s;
    if (this.sesion.sucursal) {
      this.sesion.saveSucursal(this.sesion.sucursal.id);
    }
    let aux = this.router.url;
    this.router.navigate(['loading']);
    setTimeout(() => {
      this.router.navigate([aux]);
    }, 0);
  }

  cargarStockCritico(): void {
    this.loading.show();
    this.productoService.stockCritico(this.index).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.stockCriticos = res.elements;
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  crearOT(): void {
    this.router.navigate(['/revisiones/create']);
  }

  soporteLubriKuv(): void {
    const modalRef = this.modalService.open(ModalContactoSoporteComponent, { windowClass: 'clear-modal', size: 's', scrollable: true, centered: true });
    modalRef.componentInstance.soloDemo = this.sesion.user.cuenta_prueba;
    modalRef.result.then((result: any) => {
    }, (reason: any) => { });
  }


  showModalAlertasSII(): void {
    const modalRef = this.modalService.open(InfoAlertComponent, { windowClass: 'clear-modal', size: 's', scrollable: true, backdrop: 'static', centered: true });
    modalRef.componentInstance.tipoAlerta = 2;
    modalRef.componentInstance.isDanger = true;
    modalRef.result.then((result: any) => {
    }, (reason: any) => { });
  }


  async realizarPagoPremium() {
    this.loading.show();
    let suscripcion_activa = await this.sesion.obtenerEstadoSuscripcion();
    this.loading.hide();
    if (suscripcion_activa) {
      const modalRef = this.modalService.open(ModalDeleteComponent, { size: 'lg', scrollable: true, centered: true });
      modalRef.componentInstance.texto = 'Ya posee una suscripción activa.';
      modalRef.componentInstance.textoAceptar = 'Aceptar';
      modalRef.result.then((result: any) => { }, (reason: any) => { });
      return;
    }
    const modalRef = this.modalService.open(KpagosInformacionPremiumComponent, { size: 'xl', scrollable: true, centered: true });
    modalRef.result.then((result: any) => { }, (reason: any) => { });
  }
}
