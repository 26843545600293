import { Component, OnInit } from '@angular/core';
import { KpagosService } from '../kpagos.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { DateService } from 'src/app/utils/date/date.service';
import { KpagosTerminosComponent } from '../kpagos-terminos/kpagos-terminos.component';

@Component({
  selector: 'app-kpagos-informacion-premium',
  templateUrl: './kpagos-informacion-premium.component.html',
  styleUrls: ['./kpagos-informacion-premium.component.scss']
})
export class KpagosInformacionPremiumComponent implements OnInit {

  montoMensual: number = 0;
  montoAnual: number = 0;
  montos = [350, 350, 1388, 1027];
  dias: number = 0;
  fecha: Date = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public service: KpagosService,
    public formatter: FormatterService,
    public date: DateService,
    private modalService: NgbModal
  ) {}
  
  ngOnInit() {
    this.fecha = this.date.addTime('+1 mes', this.date.firstDayOfMonth());
    // let diff = this.date.dateDiff(this.fecha, this.date.today());
    // this.dias = this.date.dateDiff(this.fecha, this.date.today(), 'days');
    let dia = this.date.extract(new Date()).dia;
    this.dias = 30 - ((dia == 31 ? 30 : dia) - 1);
    this.montoMensual = this.montos[2] * this.dias;
    this.montoAnual = this.montos[3] * this.dias;
  }

  seleccionarPlan(tipo: number){
    const modalRef = this.modalService.open(KpagosTerminosComponent,{ size: 'lg', scrollable: true,  centered: true });
    modalRef.componentInstance.tipo = tipo;
    modalRef.result.then((result: any) => {
      this.activeModal.close();
    }, (reason: any) => {
      this.activeModal.close();
    });
  }

}
