import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class KpagosService {

	constructor(
		private http: HttpClient,
		private config: ConfigService,
		private sesion: SesionService
	) { }

	controller: string = 'kpagos/';

	create(element: any): Observable<any> {
		return this.http.post(this.config.apiUrl + this.controller + `create`, element, this.sesion.headers);
	}

	view(id: number): Observable<any> {
		return this.http.get(this.config.apiUrl + this.controller + `view/${id}`, this.sesion.headers);
	}

	select(): Observable<any> {
		return this.http.get(this.config.apiUrl + this.controller + `select`, this.sesion.headers);
	}

	update(element: any): Observable<any> {
		return this.http.put(this.config.apiUrl + this.controller + `update/${element.id}`, element, this.sesion.headers);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(this.config.apiUrl + this.controller + `delete/${id}`, this.sesion.headers);
	}

	list(): Observable<any> {
		return this.http.get(this.config.apiUrl + this.controller + `list`, this.sesion.headers);
	}

	activate(id: number): Observable<any> {
		return this.http.put(this.config.apiUrl + this.controller + `activate/${id}`, {}, this.sesion.headers);
	}

	deactivate(id: number): Observable<any> {
		return this.http.put(this.config.apiUrl + this.controller + `deactivate/${id}`, {}, this.sesion.headers);
	}

	getEnlacePago(id: number, data: any){
		return this.http.post(this.config.apiUrl + this.controller + `solicitar-enlace-pago/${id}`, data, this.sesion.headers);
	}
}
