import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
  ) { }

  meses: string[] = ['', 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
  dias: string[] = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

  private capitalize(texto: string) {
    return texto.substring(0, 1).toUpperCase() + texto.substring(1);
  }

  today(): Date{
    return new Date();
  }

  todayString(): string {
    return new Date().toISOString().substring(0,16);
  }

  format(date: Date = new Date(), format: string = 'yyyy-mm-dd HH:ii:ss'): string {
    let result = format;

    let data = this.extract(date);

    result = result.replace(/yyyy/g, data.anio.toString());

    result = result.replace(/MMM/g, this.meses[data.mes].toUpperCase());
    result = result.replace(/Mmm/g, this.capitalize(this.meses[data.mes]));
    result = result.replace(/mmm/g, this.meses[data.mes]);
    result = result.replace(/DDD/g, this.dias[data.diaSemana].toUpperCase());
    result = result.replace(/Ddd/g, this.capitalize(this.dias[data.diaSemana]));
    result = result.replace(/ddd/g, this.dias[data.diaSemana]);

    result = result.replace(/yy/g, data.anio.toString().substring(0, 2));
    result = result.replace(/mm/g, data.mes2);
    result = result.replace(/dd/g, data.dia2);
    result = result.replace(/HH/g, data.hours2);
    result = result.replace(/ii/g, data.minutes2);
    result = result.replace(/ss/g, data.seconds2);

    result = result.replace(/m/g, data.mes.toString());
    result = result.replace(/d/g, data.dia.toString());
    result = result.replace(/H/g, data.hours.toString());
    result = result.replace(/i/g, data.minutes.toString());
    result = result.replace(/s/g, data.seconds.toString());

    return result;
  }

  formatString(dateString: string, format: string = 'yyyy-mm-dd HH:ii:ss'): string {
    let date = new Date(dateString);

    return this.format(date, format);
  }

  extract(date: Date = new Date()): { dia: number, dia2: string, diaSemana: number, mes: number, mes2: string, anio: number, hours: number, hours2: string, minutes: number, minutes2: string, seconds: number, seconds2: string} {
    let dia = date.getDate();
    let dia2 = (dia < 10) ? `0${dia}` : dia.toString();
    let diaSemana = date.getDay();
    let mes = (date.getMonth() - 0) + 1;
    let mes2 = (mes < 10) ? `0${mes}` : mes.toString();
    let anio = date.getFullYear();
    let hours = date.getHours();
    let hours2 = (hours < 10) ? `0${hours}` : hours.toString();
    let minutes = date.getMinutes();
    let minutes2 = (minutes < 10) ? `0${minutes}` : minutes.toString();
    let seconds = date.getSeconds();
    let seconds2 = (seconds < 10) ? `0${seconds}` : seconds.toString();

    return { 
      dia, 
      dia2, 
      diaSemana, 
      mes, 
      mes2, 
      anio, 
      hours, 
      hours2, 
      minutes, 
      minutes2, 
      seconds, 
      seconds2 
    };
  }

  addTime(time: string, date: Date = new Date()): Date {
    let data = this.extract(date);
    let modify = time.split(' ');
    let sign = modify[0].substring(0,1);
    let q = parseInt(modify[0].substring(1, modify[0].length));
    if(sign == '-') q = 0 - q;
    let type = modify[1];
    switch (type) {
      case 'day':
        data.dia += q;
        break;
      case 'days':
        data.dia += q;
        break;
      case 'hour':
        data.hours += q;
        break;
      case 'hours':
        data.hours += q;
        break;
      case 'minute':
        data.minutes += q;
        break;
      case 'minutes':
        data.minutes += q;
        break;
      case 'month':
        data.mes += q;
        break;
      case 'months':
        data.mes += q;
        break;
      case 'second':
        data.seconds += q;
        break;
      case 'seconds':
        data.seconds += q;
        break;
      case 'year':
        data.anio += q;
        break;
      case 'years':
        data.anio += q;
        break;
      default:
        break;
    }
    return new Date(data.anio, data.mes -1, data.dia, data.hours, data.minutes, data.seconds)
  }

  dayOfMonth(day: number, date: Date = new Date()): Date {
    let data = this.extract(date);
    return new Date(data.anio, data.mes - 1, day, data.hours, data.minutes, data.seconds)
  }

  lastDayOfMonth(date: Date = new Date()): Date {
    let data = this.extract(date);
    return new Date(data.anio, data.mes, 0, data.hours, data.minutes, data.seconds)
  }

  firstDayOfMonth(date: Date = new Date()): Date {
    let data = this.extract(date);
    return new Date(data.anio, data.mes - 1, 1, data.hours, data.minutes, data.seconds)
  }

  firstDayOfWeek(date: Date = new Date()): Date {
    let data = this.extract(date);
    return new Date(data.anio, data.mes - 1, data.dia - (data.diaSemana == 0 ? 6 : data.diaSemana - 1), data.hours, data.minutes, data.seconds)
  }

  diasDelMes(date: Date = new Date()): number {
    let data = this.extract(this.lastDayOfMonth(date));
    return data.dia;
  }

  dateDiff(fecha1: Date, fecha2: Date, tipo: 'json' | 'days' | 'months' | 'years' | 'hours' | 'seconds' | 'minutes' | 'string' = 'json'): any{
    if(fecha1 > fecha2) {
      let aux = fecha1;
      fecha1 = fecha2;
      fecha2 = aux;
    }
    let data1 = this.extract(fecha1);
    let data2 = this.extract(fecha2);

    let json = {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }

    json.seconds = data1.seconds - data2.seconds;
    if (json.seconds < 0) {
      json.seconds += 60;
      data1.minutes--;
    }
    json.minutes = data1.minutes - data2.minutes;
    if (json.minutes < 0) {
      json.minutes += 60;
      data1.hours--;
    }
    json.hours = data1.hours - data2.hours;
    if (json.hours < 0) {
      json.hours += 24;
      data1.dia--;
    }
    json.days = data1.dia - data2.dia;
    if (json.days < 0) {
      json.days += this.diasDelMes(fecha1);
      data1.mes--;
    }
    json.months = data1.mes - data2.mes;
    if (json.months < 0) {
      json.months += 12;
      data1.anio--;
    }
    json.years = data1.anio - data2.anio;
    switch (tipo) {
      case 'days':
        var t2 = fecha2.getTime();
        var t1 = fecha1.getTime();

        return Math.floor((t2 - t1) / (24 * 3600 * 1000));
      case 'hours':
        return 0;
      case 'json':
        return 'json';
      case 'minutes':
        return 0;
      case 'months':
        return (json.years * 12) + (json.months);
      case 'seconds':
        return 0;
      case 'string':
        return `${json.years} año${json.years != 1 ? 's' : ''}, ${json.months} mes${json.months != 1 ? 'es' : ''}, ${json.days} día${json.days != 1 ? 's' : ''}, ${json.hours} hora${json.hours != 1 ? 's' : ''}, ${json.minutes} minuto${json.minutes != 1 ? 's' : ''} y ${json.seconds} segundo${json.seconds != 1 ? 's' : ''}`;
      case 'years':
        return (json.years);
      default:
        return 0;
    }
  }

  dateDiffFixed(fecha1: Date, fecha2: Date, tipo: 'json' | 'days' | 'months' | 'years' | 'hours' | 'seconds' | 'minutes' | 'string' = 'json'): any {
    let data1 = this.extract(fecha1);
    let data2 = this.extract(fecha2);

    let json = {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }

    json.seconds = data1.seconds - data2.seconds;
    if (json.seconds < 0) {
      json.seconds += 60;
      data1.minutes--;
    }
    json.minutes = data1.minutes - data2.minutes;
    if (json.minutes < 0) {
      json.minutes += 60;
      data1.hours--;
    }
    json.hours = data1.hours - data2.hours;
    if (json.hours < 0) {
      json.hours += 24;
      data1.dia--;
    }
    json.days = data1.dia - data2.dia;
    if (json.days < 0) {
      json.days += this.diasDelMes(fecha1);
      data1.mes--;
    }
    json.months = data1.mes - data2.mes;
    if (json.months < 0) {
      json.months += 12;
      data1.anio--;
    }
    json.years = data1.anio - data2.anio;
    switch (tipo) {
      case 'days':
        var t2 = fecha2.getTime();
        var t1 = fecha1.getTime();

        return Math.floor((t2 - t1) / (24 * 3600 * 1000));
      case 'hours':
        return 0;
      case 'json':
        return 'json';
      case 'minutes':
        return 0;
      case 'months':
        return (json.years * 12) + (json.months);
      case 'seconds':
        return 0;
      case 'string':
        return `${json.years} año${json.years != 1 ? 's' : ''}, ${json.months} mes${json.months != 1 ? 'es' : ''}, ${json.days} día${json.days != 1 ? 's' : ''}, ${json.hours} hora${json.hours != 1 ? 's' : ''}, ${json.minutes} minuto${json.minutes != 1 ? 's' : ''} y ${json.seconds} segundo${json.seconds != 1 ? 's' : ''}`;
      case 'years':
        return (json.years);
      default:
        return 0;
    }
  }
}
