import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contacto-soporte',
  templateUrl: './contacto-soporte.component.html',
  styleUrls: ['./contacto-soporte.component.scss'],
})
export class ModalContactoSoporteComponent implements OnInit {

  @Input() soloDemo: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() { }

  guardar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.dismiss();
  }

  email(): void {
    const mailText = "mailto:soporte@kuvemar.com+?subject=files&body=";
    window.location.href = mailText;
    this.activeModal.dismiss();
  }

  whatsApp(): void { 
    const whatsApp = "https://api.whatsapp.com/send?phone=56944758039";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  whatsAppKuvemar(): void { 
    const whatsApp = "https://api.whatsapp.com/send?phone=56975155189";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  tel(): void { 
    const tel = "tel:+56975155189";
    window.location.href = tel;
    this.activeModal.dismiss();
  }
}
