import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { DateService } from 'src/app/utils/date/date.service';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { ModalDeleteComponent } from 'src/app/utils/modal-delete/modal-delete.component';
import { KpagosService } from '../kpagos.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Component({
  selector: 'app-kpagos-pago-en-proceso',
  templateUrl: './kpagos-pago-en-proceso.component.html',
  styleUrls: ['./kpagos-pago-en-proceso.component.scss']
})
export class KpagosPagoEnProcesoComponent implements OnInit {
  @Input() monto: number = 0;
  @Input() tipo: number = 0;
  url: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public service: KpagosService,
    public formatter: FormatterService,
    public date: DateService,
    private modalService: NgbModal,
    private sesion: SesionService
  ) { }
  
  ngOnInit(): void {
  }
  
  abirEnlacePago(){
    if(this.url) {
      window.open(this.url, "_blank");
      return;
    }
    this.loading.show();
    this.service.getEnlacePago(this.sesion.user.empresa_id, { monto: this.monto, tipo: this.tipo }).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.url = res.url;
        window.open(this.url, "_blank");
      }, error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("No se pudo guardar la .", "danger");
      }
    })
  }
}
