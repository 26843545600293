import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import * as data from './routes.json';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  ROL_ADMINISTRADOR_KUVEMAR = 1;
  ROL_ADMINISTRADOR = 1;
  ROL_EDITOR = 2;
  ROL_VISUALIZADOR = 3;
  ROL_CLIENTE = 4;
  ROL_CAJERO = 5;
  ROL_OPERADOR = 6;
  ROL_JEFE_TALLER = 7;

  get username() { return this.user.nombre; };
  get id() { return this.user.id };
  get token(): string { return localStorage.getItem('token') ?? '' };
  get user() { let usrStr = localStorage.getItem('usuario'); if (usrStr) return JSON.parse(usrStr); else return {}; };
  get rol() { return this.user.rol_id };
  get conceptoActivado(): boolean { return this.user.parametros && this.user.parametros.concepto_activado == '1' };
  get siiActivado(): boolean { return this.user.parametros && this.user.parametros.sii_activado == '1' };

  routes: any[] = [];
  notifications: any[] = [];
  sucursales: any[] = [];
  sucursal: any = null;
  sucursalIndex: number = 0;

  controller: string = "sesion/";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  get headers() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
        'Sucursal': this.sucursal ? `${this.sucursal.id}` : `${this.loadLocalSucursal()}` ?? '',
      })
    }
  };

  get headersBlob() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
        'Sucursal': this.sucursal ? `${this.sucursal.id}` : `${this.loadLocalSucursal()}` ?? '',
      }),
      responseType: 'blob' as 'json',
    }
  };

  get headersFile() {
    return {
      headers: new HttpHeaders({
        'Authorization': this.token,
        'Sucursal': this.sucursal ? `${this.sucursal.id}` : `${this.loadLocalSucursal()}` ?? '',
      })
    }
  }

  get headersFileBlob() {
    return {
      headers: new HttpHeaders({
        'Authorization': this.token,
        'Sucursal': this.sucursal ? `${this.sucursal.id}` : `${this.loadLocalSucursal()}` ?? '',
      }),
      responseType: 'blob' as 'json',
    }
  }

  get headersHTML() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.token,
        'Sucursal': this.sucursal ? this.sucursal.id : '',
      }),
      responseType: 'text/html' as 'json',
    }
  }

  private rutasSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  rutas$ = this.rutasSubject.asObservable();

  setRutas(rutas: any[]): void {
    this.rutasSubject.next(rutas);
    localStorage.setItem('rutas', JSON.stringify(rutas));
  }

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private router: Router,
  ) {
    this.loadRutasFromLocalStorage();
  }

  private loadRutasFromLocalStorage() {
    const rutasStr = localStorage.getItem('rutas');
    if (rutasStr) {
      const rutas = JSON.parse(rutasStr);
      this.rutasSubject.next(rutas);
    }
  }

  goToHome(): void {
    if(this.routes.length == 0) {
      setTimeout(() => {
        this.goToHome();
      }, 100);
      return;
    }

    this.router.navigate([this.routes[0].route ?? this.routes[0].children[0].route]);
  }

  login(credenciales: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "login", credenciales, this.httpOptions)
      .pipe(
        map((resp: any) => {
          localStorage.setItem('id', resp.usuario.id);
          localStorage.setItem('token', resp.token);
          localStorage.setItem('usuario', JSON.stringify(resp.usuario));
          localStorage.setItem('sucursal_id', "");
          return resp.usuario;
        })
      );
  }

  loginAsUser(idUsuario: number): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + `login-as-user/${idUsuario}`, this.headers)
      .pipe(
        map((resp: any) => {
          localStorage.setItem('id', resp.usuario.id);
          localStorage.setItem('token', resp.token);
          localStorage.setItem('usuario', JSON.stringify(resp.usuario));
          localStorage.setItem('sucursal_id', "");
          return resp.usuario;
        })
      );
  }

  isLogged(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "is-logged", this.headers)
      .pipe(
        map((resp: any) => {
          if(resp.usuario) {
            localStorage.setItem('id', resp.usuario.id);
            localStorage.setItem('token', resp.token);
            localStorage.setItem('usuario', JSON.stringify(resp.usuario));
            localStorage.setItem('sucursal_id', "");
          }
          return resp;
        })
      );
  }

  getPaymentState(id: number | null = null): Observable<any> {
    return this.http.get(this.config.apiUrl + `kpagos/comprobar-suscripcion-local/${id}`, this.headers);
  }

  checkPaymentState(id: number | null = null): Observable<any> {
    return this.http.get(this.config.apiUrl + `kpagos/comprobar-suscripcion/${id}`, this.headers);
  }

  getMenu(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "obtener-menu", this.headers);
  }

  saveSesion(token: string, usuario: any): void {
    localStorage.setItem("token", token);
    localStorage.setItem("id", usuario.id);
    localStorage.setItem("usuario", JSON.stringify(usuario));
  }

  clearSesion(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("id");
    localStorage.removeItem("sucursal_id");
    this.sucursalIndex = 0;
    this.sucursal = null;
    this.sucursales = [];
    //this.rutas = []
  }

  loadLocalSucursal(): number {
    try {
      const index = localStorage.getItem("sucursal_id") ?? "";
      if (!index) return 0;
      return parseInt(index);
    } catch (_) {
      localStorage.setItem("sucursal_id", "");
      return 0;
    }
  }

  saveSucursal(id: number): void {
    if (!id) return;
    localStorage.setItem("sucursal_id", `${id}`);
  }

  restablecer(credenciales: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': credenciales.token
    });
    return this.http.post(this.config.apiUrl + this.controller + "restablecer", credenciales, { headers });
  }

  recuperar(credenciales: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "recuperar-pass", credenciales, this.httpOptions);
  }

  changePassword(pass: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + "change-password", pass, this.headers);
  }

  getLatestVersion(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "get-latest-version", this.headers);
  }

  getLatestVersionMd(): Observable<any> {
    return this.http.get(this.config.apiUrl + this.controller + "get-latest-version-md", this.headers);
  }

  obtenerEstadoSuscripcion(id: number | null = null) {
    return new Promise((resolve, reject) => {
      this.getPaymentState(id).subscribe({
        next: (res: any) => {
          if (!res.suscripcion_activa) {
            this.checkPaymentState(id).subscribe({
              next: (res: any) => {
                if (!res.suscripcion_activa) resolve(false);
                else resolve(true);
              }, error: (err: any) => {
                resolve(false);
              }
            });
          } else {
            resolve(true);
          }
        }, error: (err: any) => {
          resolve(false);
        }
      });
    })
  }
}