<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h4 class="d-flex justify-content-center mb-5">Comencemos seleccionando uno de nuestros planes:</h4>
        <div class="d-flex justify-content-evenly mb-5">
            <div class="card mensual" (click)="seleccionarPlan(3)">
                <div class="d-flex flex-column justify-content-between h-100 align-items-center">
                    <div class="title">Mensual</div>
                    <div class="monto">$ 35.000 <div class="iva">+ IVA</div></div>
                </div>
                <div class="text">Incluye todas las funciones.</div>
                <div class="text mt-3" style="font-size: 12px;">$ {{formatter.numberFormat(montoMensual)}} el primer mes (*)</div>
            </div>
            <div class="card anual" (click)="seleccionarPlan(4)">
                <div class="title">Anual</div>
                <div class="text">Ahorra $ 105.000 (25 %)</div>
                <div class="monto">$ 315.000 <div class="iva">+ IVA</div></div>
                <div class="text">Incluye todas las funciones.</div>
                <div class="text mt-3" style="font-size: 12px;">$ {{formatter.numberFormat(montoAnual)}} el primer mes (*)</div>
            </div>
        </div>
        <div class="d-flex justify-content-center">* El primer mes se realizará un cobro proporcional por los días hasta el primer día del próximo mes ({{dias}} día{{dias == 1 ? '' : 's'}}).</div>
    </div>
    <div class="modal-footer">
    </div>
</div>