<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h4 class="text-center">Continúe con el pago a través de Flow.</h4>
        <div class="w-100 text-center d-flex flex-column gap-2 justify-content-center align-items-center">
            <img style="max-width: 150px; max-height: 150px; cursor: pointer;" src="/assets/images/logo-flow.jpg"
                alt="Payment in process">
            <!-- <img style="max-width: 500px; max-height: 500px;" src="/assets/images/payment.jpg" alt="Payment in process"> -->
            <!-- <div style="font-size: 10px; color: #777;">Image by Vectorjuice</div> -->
        </div>
        <div class="d-flex justify-content-center">
            <div class="mb-4 btn btn-primary btn-lg" (click)="abirEnlacePago()">
                Ir al pago
            </div>
        </div>
        <div class="text-center">El pago puede tardar unos minutos en ser procesado. Si aún no se actualiza su cuenta,
            puede cerrar esta ventana y esperar un momento.</div>
    </div>
    <div class="modal-footer">
    </div>
</div>